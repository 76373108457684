import React from 'react';
import Layout from './src/components/layout/layout'
import Header from './src/components/layout/header'
// import { ContextProviderComponent } from './src/components/layout/context'

// export const onPreRouteUpdate = ({ location, prevLocation }) => {
//   console.log("new pathname", location.pathname)
//   console.log("old pathname", prevLocation ? prevLocation.pathname : null)
// }

export const wrapPageElement = ({ element, props }) => (
    <Layout {...props}>
        <Header post={props.post}/>
        {element}
    </Layout>
)

