import React from "react"
import posed from 'react-pose';
import { TransitionState } from "gatsby-plugin-transition-link";

const FadingContent = posed.div({
  exiting:  { opacity: 0},
  exited:   { opacity: 0 },
  entering: { opacity: 1},
  entered:  { opacity: 1},
  transition: {
    // default: { ease: 'linear', duration: 500 }
    type: 'spring',
    values: [0, 100, 100],
    stiffness: 400,
    dampness: 500
  }
})

const Transition = ({ children }) => {

  return(
    <TransitionState>
      {({ transitionStatus }) => (
        
      <FadingContent pose={transitionStatus}>
          {children}
      </FadingContent>
      )}
    </TransitionState>
  )
}


export default Transition

const TRANSITION_LENGTH = 0.3

const exitTransition = {
  // delay: TRANSITION_LENGTH,
  length: TRANSITION_LENGTH, // Take 1.5 seconds to leave
  // trigger: () => console.log('We are exiting'),
}

const entryTransition = {
  delay: TRANSITION_LENGTH, // Wait 1.5 seconds before entering
  length: TRANSITION_LENGTH, // Take 1.5 seconds to leave
  // trigger: () => console.log('We are entering'),
}

export { exitTransition, entryTransition }


