


import React, { useState, useEffect, createContext, useContext } from "react"

const defaultContextValue = {
  data: {
    menuOpen: false,
  },
  set: () => {},
}

const { Provider, Consumer } = React.createContext(defaultContextValue)

const ContextProviderComponent = (props) =>  {

  const setData = (newData) => {

    // console.log("set net Data", newData)

    // setState(prevState => ({
    //   ...prevState,
    //   data: {
    //     ...newData,
    //   },
    // }))

    if(!newData.menuOpen){
    // If menu is closed delay opening …
      setTimeout(() => {
      
        setState(prevState => ({
          ...prevState,
          data: {
            ...newData,
          },
        }))

      }, 1000)

    }else{
      // If menu is open close imediately
      setState(prevState => ({
        ...prevState,
        data: {
          ...newData,
        },
      }))
    }
  }
  
  const [ state, setState ] = useState({
    ...defaultContextValue,
    set: setData,
  })

  return <Provider value={state}>{props.children}</Provider>
}

export { Consumer as default, ContextProviderComponent }
