let visitedPosts = []

const isVisited = (post) => {
    // console.log("check if already visited", post)
    let exists = false
    visitedPosts.forEach((item, i) => {
        
        if(item === post ){
            exists = true
        }
    })
    return exists
}

const addVisited = (post) => {
    // console.log("add post", post, visitedPosts)
    let exists = false
    visitedPosts.forEach((item, i) => {
        
        if(item === post ){
            // console.log("match!!", post, visitedPosts)
            exists = true
        }
    })
    if(!exists) visitedPosts.push(post)
}

export { isVisited, addVisited }

export default visitedPosts