import React, { useState } from "react"
import { StaticQuery, graphql } from "gatsby"


import "./header.scss"

// import Fader from '../fader/fader'
// import search from '../../helpers/searchValueInObjectInArray'

// Page Transitions
import TransitionLink from 'gatsby-plugin-transition-link'
import { exitTransition, entryTransition} from '../transition/transition'
import ContextConsumer from "./context"

import visitedProjects from '../../globals/config'

const randomPosition = (radius) => {
  const randomPosNegX = Math.random > 0.5 ? 1 : -1
  const randomPosNegY = Math.random > 0.5 ? 1 : -1

  const padding = 40;
  const posX = Math.floor(Math.random() * 80 - 40) * randomPosNegX
  const posY = Math.floor(Math.random() * 60 - 30) * randomPosNegY

  const posRelative = `translate(calc(${posX}vw - ${radius*-randomPosNegX}px), calc(${posY}vh - ${radius*-randomPosNegY}px))`

  return posRelative
}

export default () => {
  const [ state, setState ] = useState({
    intro: false,
    styles: [
      { transform: randomPosition(100) },
      { transform: randomPosition(100) },
      { transform: randomPosition(100) },
    ],
    positions: visitedProjects.map(() => randomPosition(50)),
  })


  const handleMenu = (set, data) => {
    if(!data.menuOpen){
      set({ menuOpen: true })
    }else{
      setState({
        ...state,
        styles: [
          // { transform: `translate(${Math.floor(Math.random() * 60) - 30}vw, ${Math.floor(Math.random() * 60) - 30}vh)` },
          // { transform: `translate(${Math.floor(Math.random() * 60) - 30}vw, ${Math.floor(Math.random() * 60) - 30}vh)` },
          // { transform: `translate(${Math.floor(Math.random() * 60) - 30}vw, ${Math.floor(Math.random() * 60) - 30}vh)` },
          { transform: randomPosition(100) },
          { transform: randomPosition(100) },
          { transform: randomPosition(100) },
        ],
        positions: visitedProjects.map(() => randomPosition(50))
      })
    }
  }

  // useEffect(() => {
  //   setTimeout(() => {
  //     setState({
  //       ...state,
  //       intro: false
  //     })
  //   }, 600)
  // })


  return(
    <StaticQuery
    query={graphql`
      query HeadingQuery {
        wpgraphql{
          categories{
            nodes {
              slug
              name
            }
          }
        }
      }
    `}
    render={data => (
    <header>
      <ContextConsumer>
        {({ data, set }) => (
          <div 
            onClick={() => set({ menuOpen: !data.menuOpen })} 
            tabIndex={0} 
            role="button"
            aria-label="Close Menu"
            className={data.menuOpen ? "nav active" : "nav"}
          >
            {/* <div className="nav__background"  
       
              onClick={() => closeMenu(set)} 
              onKeyDown={() => closeMenu(set)} 
              onClick={() => set({ menuOpen: !data.menuOpen })} 
              onKeyDown={() => set({ menuOpen: !data.menuOpen })} 
              tabIndex={0} 
              role="button"
              aria-label="Close Menu"
            /> */}
            <div className="nav__item" style={state.styles[0]}>
              <TransitionLink 
                  to="/"
                  exit={exitTransition}
                  entry={entryTransition}>
                <h3 className="nav__text">Projects</h3>
                <div className="nav__bubble"></div>
              </TransitionLink>
            </div>
            <div className="nav__item" style={state.styles[1]}>
              <TransitionLink 
              to="/info"
              exit={exitTransition}
              entry={entryTransition}>
                <h3 className="nav__text">Info</h3>
                <div className="nav__bubble"></div>
              </TransitionLink>
            </div>
            <div className="nav__item" style={state.styles[2]}>
              <TransitionLink 
              to="/news"
              exit={exitTransition}
              entry={entryTransition}>
                <h3 className="nav__text">News</h3>
                <div className="nav__bubble"></div>
              </TransitionLink>
            </div>
            {
              visitedProjects ? visitedProjects.map((project, i) => {

                const style = {transform: state.positions[i]}

                return(
                  <div key={i} className="nav__project__wrapper">
                    <div className="nav__project" style={style}>
                      <TransitionLink 
                      to={project.slug}
                      exit={exitTransition}
                      entry={entryTransition}>
                        <div className="nav__bubbleSmall"></div>
                      </TransitionLink>
                    </div>
                    <h3 className="nav__text__project">{project.title}</h3>
                  </div>
                )
              }) : false
            }
          </div>  
        )}
      </ContextConsumer>
      <ContextConsumer>
        {({ data, set }) => (
          <div id="nav__dot" onClick={() => { handleMenu(set, data) }} className={`${data.menuOpen ? "active" : ""} ${state.intro ? "intro" : ""}`} />
        )}
      </ContextConsumer>
    </header>
    )} />
  )
}
