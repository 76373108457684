import React from "react"

import "./layout.scss"

import { ContextProviderComponent } from "./context"


export default (props) => {

  const className = props.className || props.uri ? props.uri.split('/')[1] : false

  const handleOnScroll = () => {

    const body = document.body;
    const main = document.querySelector('main');

    if(main.scrollTop > 100){
      body.classList.add('scroll')
    }else{
      body.classList.remove('scroll')
    }
  }

  let newClassName = ""
  if(className === "news" || className === "info" || className === ""){
    newClassName = className
  }else{
    newClassName = "post"
  }

  return (
    <ContextProviderComponent>
      <div className={`page page__${newClassName}`}>
        <div>
          <div>
            <main onScroll={handleOnScroll} >{props.children}</main>
          </div>
        </div>
      </div>
    </ContextProviderComponent>
  )
}
